.c3content {
    width: 50%;
    height: 600px;
}

.c3content div{
    color: white;
}

.c3shell {
    position: relative;
    user-select: none;
    width: 100vh;
    height: 100%;
}

.c3item {
    pointer-events: none;
    position: absolute;
    width: 399px;
    height: 399px;
    overflow: hidden;
    transition: all .3s ease-in-out;
    z-index: -1;
    opacity: 0;
    background-color: transparent;
}


.c3item.active {
    pointer-events: unset;
    opacity: 1;
    z-index: 99;
    transform: scale(1.2);
    /*box-shadow: 0px 0px 105px -35px rgba(0, 0, 0, 0.75);*/
}

.c3item.prev {
    z-index: 2;
    opacity: 0.25;
    transform: translate(-100%, 0);
}

.c3item.next {
    z-index: 2;
    opacity: 0.25;
    transform: translate(100%, 0);
}

.c3item img {
    width: 70%;
    height: 70%;
    border-radius: 50%;
}

.active .c3scrolling-text {
    overflow: hidden;
    white-space: nowrap;
    color: black;
}

.active2 .c3scrolling-text div{
    display: inline-block;
    padding-left: 100%;
    animation: marquee2 3s linear infinite; /* 调整动画时长和效果 */
}

.c3iconSize {
    font-size: 30px
}

.c3button-container {
    min-width: 250px;
    height: 90%;
    width: 100%;
}

.c3button {
    color: #fff;
    font-size: 32px;
    cursor: pointer;
    position: relative;
    transition: all 300ms ease-in-out;
}

.c3button:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    background-color: rgba(5, 5, 5, 0.521);
    border-radius: 50%;
    z-index: -99;
}

.c3button:nth-child(1) {
    float: left;
}

.c3button:nth-child(3) {
    float: right;
}

@media (max-width: 1200px) {
    .c3item {
        width: 300px;
        height: 300px;
    }

    .c3button {
        font-size: 20px;
    }

    .c3button:before {
        width: 35px;
        height: 35px;
    }

    .c3iconSize {
        font-size: 20px
    }

}

@media (max-width: 900px) {
    .c3item {
        width: 250px;
        height: 250px;
    }
}

@media (max-width: 760px) {
    .c3content {
        height: 399px;
    }

    .c3item {
        width: 200px;
        height: 200px;
    }
}

@media (max-width: 620px) {
    .c3content {
        height: 300px;
    }

    .c3item {
        width: 150px;
        height: 150px;
    }
}

@media (max-width: 425px) {
    .c3content {
        height: 200px;
    }

    .c3item {
        width: 105px;
        height: 115px;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes marquee2 {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}