body{
    background-color: rgba(165, 188, 248, 0.29);
    /*background-color: #0281C8;*/
    /*background-color: #29416F;*/
    /*background-color: #baccd9;*/
    /*background-color: #2775b6;*/
    /*background-color: #11659a;*/
    /*background-color: #1c2938;*/
    /*background-color: #9AA1BA;*/


    /*color: white;*/
}

.ant-carousel .slick-dots li.slick-active button {
    background: #000;
    opacity: 1;
}

.ant-carousel .slick-dots li button {
    background: #000;
}

.normalLink,
.normalLink:hover,
.normalLink:active,
.normalLink:visited
{
    text-decoration: none;
    color: #000;
}

.pb,
.pb:hover
{
    background: #FFFFFF;
    width: auto;
    height: 63px;
    color: #000000;
    border: 2px solid #FFF;
    border-radius: 20px;
    font-size: 20px;
    margin: 20px;
    padding: 10px 60px;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 39px;
}

.pb p {
    display: inline;
}

.pbGroup {
    width: auto;
    background-image: linear-gradient(to bottom, #16b8f3, #5BC2E7);
    border-radius: 50px;
}

.apb,
.apb:hover,
.apb:visited
{
    color: black;
    text-decoration: none;
}

#webpack-dev-server-client-overlay {
    display: none !important;
}

.sectionHeader {
    width: 100%;
    height: 90vh;
}

.sectionHeader2 {
    width: 100%;
    height: 20vh;
}

.sectionHeader3 {
    width: 100%;
    height: 60vh;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .sectionHeader {
        width: 100%;
        height: 50vh;
    }

    .pb {
        background: #FFFFFF;
        width: auto;
        height: 63px;
        color: #000000;
        border: 2px solid #FFF;
        border-radius: 20px;
        font-size: 10px;
        margin: 5px;
        padding: 10px 10px;
        text-decoration: none;
        /*overflow: hidden;*/
        /*text-overflow: ellipsis;*/
        /*white-space: nowrap;*/
        line-height: 39px;
    }
}

.bgc {
    background-position: center;
    background-size: cover;
}

@keyframes pulse-it {
    0% {
        text-shadow: 0 0 0 #FF0000;
        opacity: 0.3;
    }
    50% {
        text-shadow: 0 0 5px #FF0000;
        opacity: 0.9;
    }
    100% {
        text-shadow: 0 0 0 #FF0000;
        opacity: 0.3;
    }
}

.sad-text {
    margin: 20px;
    animation: pulse-it 3s infinite;
}