/* 打字机文本内容 */
.text {
    box-sizing: border-box;
    padding: 1rem;
    font-size: 23px;
}

/* 光标 */
/* 在文本内容的最后通过添加行内元素以达到光标跟随文字的输入移动的效果 */
.text::after {
    content: '|';
    animation: writing .6s infinite;
}

/* 光标动画 */
@keyframes writing {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

:where(.css-1b9762u).ant-statistic .ant-statistic-title,
:where(.css-dev-only-do-not-override-1b9762u).ant-statistic .ant-statistic-title {
    color: red;
}