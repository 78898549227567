.ant-picker-calendar .ant-picker-calendar-header {
    justify-content: center;
}

.ant-picker-calendar-header .ant-radio-group {
    display: none
}

.eventCalendar {
    width: 50%;
    margin-right: 50px
}

.events {
    width: 39%;
    padding-top: 50px;
}

.event {
    width: 100%;
    margin: 10px 0;
    padding: 5px 0;
    min-height: 80px;

    /* 设置阴影 */
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    /* 设置card的背景色 */
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
}

.event:hover{
    box-shadow: 0 0 20px rgba(22, 184, 243, 0.9);
}

@media (max-width: 425px) {

    .eventCalendar {
        width: 300px;
        margin-right: 0
    }

    .events {
        width: 70%;
        padding: 0;
    }

    .event {
        padding: 30px;
    }

}