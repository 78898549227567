.letter {
    width: 60%;
    min-height: 900px;
    max-width: 60%;
    margin-left: 20%;
}

.letterHeader {
    padding: 10px;
    min-height: 100px;
}

.letterContent {
    padding: 15%;
}

/*.letter p {*/
/*    width: 100%;*/
/*    margin: 0;*/
/*    border-top: 1px solid rgba(65, 65, 65, 0.5);*/
/*    min-height: 30px;*/
/*    display: flex;*/
/*    align-items: flex-end;*/
/*}*/
@media (max-width: 1750px) {
    .letterContent {
        padding: 25% 15%;
    }
}
@media (max-width: 1440px) {
    .letter {
        width: 80%;
        max-width: 80%;
        margin-left: 10%;
    }
    .letterContent {
        padding: 17%;
    }
}
@media (max-width: 1140px) {
    .letter {
        width: 98%;
        max-width: 98%;
        margin-left: 1%;
    }
    .letterContent {
        padding: 20% 15%;
    }
}
@media (max-width: 820px) {
    .letter {
        width: 98%;
        max-width: 98%;
        margin-left: 1%;
    }
    .letterContent {
        padding: 30% 13%;
    }
}
@media (max-width: 639px) {
    .letter {
        width: 98%;
        max-width: 98%;
        margin-left: 1%;
    }
    .letterContent {
        padding: 35% 13%;
    }
}

@media (max-width: 570px) {
    .letter {
        width: 98%;
        max-width: 98%;
        margin-left: 1%;
    }
    .letterContent {
        padding: 50% 12%;
    }
}

@media (max-width: 425px) {
    .letter {
        width: 98%;
        max-width: 98%;
        margin-left: 1%;
    }
    .letterContent {
        padding: 55% 12%;
    }
}

@media (max-width: 375px) {
    .letter {
        width: 98%;
        max-width: 98%;
        margin-left: 1%;
    }
    .letterContent {
        padding: 70% 12%;
    }
}

@media (max-width: 320px) {
    .letter {
        width: 98%;
        max-width: 98%;
        margin-left: 1%;
    }
    .letterContent {
        padding: 90% 12%;
    }
}