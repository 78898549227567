.rotateBox {
    position: fixed;
    top: 15%;
    right: 5%;
    z-index: 100;
}

@keyframes rotateNeedle {
    0% {
        transform: rotate(-20deg);
        transform-origin: left top;
    }
    100% {
        transform: rotate(0deg);
        transform-origin: left top;
    }
}

@-webkit-keyframes rotateNeedle {
    0% {
        transform: rotate(-20deg);
        transform-origin: left top;
    }
    100% {
        transform: rotate(0deg);
        transform-origin: left top;
    }
}

.needle {
    user-select: none;
    position: relative;
    top: -55px;
    left: 39px;
    max-height: 60px;
    animation: rotateNeedle 0.5s linear 1;
    -webkit-animation: rotateNeedle 0.5s linear 1;
}

.needleStart {
    transform: rotate(-20deg);
    transform-origin: left top;
    transition: transform 0.5s;
}

.needleEnd {
    transform: rotate(0deg);
    transform-origin: left top;
    transition: transform 0.5s;
}

.disc {
    height: 90px;
    width: 90px;
    margin-top: -50px;
}

@keyframes rotateMusic {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rotateMusic {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.rotateMusicImg {
    user-select: none;
    margin-left: 15px;
    margin-bottom: -65px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    animation: rotateMusic 20s linear infinite;
    -webkit-animation: rotateMusic 20s linear infinite;
}

.volume {
    min-width: 100px;
}

.rmvml {
    box-shadow: 0 5px 5px 5px #d5d5d5;
    color: gray;
    min-width: 333px;
    min-height: 80px;
    max-height: 80px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
}

.scrolling-text {
    overflow: hidden;
    white-space: nowrap;
}

.minst {
    user-select: none;
    margin: 5px 0;
    max-width: 80px;
}

.scrolling-text div {
    display: inline-block;
    padding-left: 100%;
    color: black;
    animation: marquee 5s linear infinite; /* 调整动画时长和效果 */
}

@keyframes marquee {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

:where(.css-1b9762u).ant-modal .ant-modal-content,
:where(.css-dev-only-do-not-override-1b9762u).ant-modal .ant-modal-content {
    padding: 0;
}