.typeBG {
    width: 70%;
    margin: 20px 0;
}

.typeHeader {
    width: 100%;
    margin: 20px 0;
}

.typeContent {
    width: 100%;
    min-height: 300px;
    max-height: 300px;
    position: relative;
    /* 设置阴影 */
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    /* 设置card的背景色 */
    /* 设置渐变 */
    background-color: rgba(255, 255, 255, 0.71);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
}

.typeContent::-webkit-scrollbar {
    width: 10px; /* 滚动条宽度 */
    background-color: #f9f9f9; /* 滚动条背景色 */
    /*display: none;*/
}

/* 自定义滚动条轨道 */
.typeContent::-webkit-scrollbar-track {
    background: #e1e1e1; /* 轨道背景色 */
    border-radius: 10px; /* 轨道圆角 */
}

/* 自定义滚动条的滑块（thumb） */
.typeContent::-webkit-scrollbar-thumb {
    background-color: #c1c1c1; /* 滑块背景色 */
    border-radius: 10px; /* 滑块圆角 */
    border: 2px solid #ffffff; /* 滑块边框 */
}

/* 当滑块悬停或活动时的样式 */
.typeContent::-webkit-scrollbar-thumb:hover {
    background: black; /* 悬停或活动状态下滑块背景色 */
}

.typeContentButton {
    margin: 10px;
    min-width: 30vh;
    white-space: nowrap; /* 确保文本在一行内显示 */
    overflow: hidden; /* 隐藏溢出的内容 */
    text-overflow: ellipsis; /* 使用省略号表示溢出的文本 */
}

@media (max-width: 500px) {

    .typeContentButton {
        padding: 0 10px;
    }

}

.type2Button{
    margin-bottom: 5px;
    margin-right: 5px;
}

.type2Button2 {
    margin: 10px 0;
    width: 39%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    border: lightgray 1px solid;
    border-radius: 15px;
    min-height: 80px;

    display: flex;
    align-items: center;
    padding-left: 20px;
}

.type2Button2:hover {
    border-color: #16b8f3;
    color: #16b8f3;
}