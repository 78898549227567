.plane {
    width: 100px;
    height: 50px;
    position: relative;
    background-color: #888;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    transform: rotate(-30deg);
    box-shadow: 0 0 10px #ccc;
}

.plane:before,
.plane:after {
    content: '';
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
}

.plane:before {
    left: -20px;
    border-top: 25px solid transparent;
    border-right: 35px solid #888;
    border-bottom: 30px solid transparent;
}

.plane:after {
    right: -20px;
    border-top: 25px solid transparent;
    border-left: 35px solid #888;
    border-bottom: 30px solid transparent;
}

/*.plane:before {*/
/*    border-bottom-left-radius: 25px;*/
/*    transform: rotate(65deg);*/
/*}*/

/*.plane:after {*/
/*    border-bottom-right-radius: 25px;*/
/*    transform: rotate(-65deg);*/
/*}*/

/*.plane:after,*/
/*.plane:before {*/
/*    z-index: -1;*/
/*}*/

/*.plane {*/
/*    left: 20%;*/
/*    top: 30%;*/
/*}*/