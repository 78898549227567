.Introduce{
    min-height:50vh;
    min-width: 100%;
    border-radius: 20px;

    /* 设置渐变 */
    background: linear-gradient(
            to right bottom,
            rgba(255, 255, 255, 0.8),
            rgba(255, 255, 255, 0.6),
            rgba(255, 255, 255, 0.8)
    );
    /* 重点：使元素背景模糊化 */
    /* 设置上高光和左高光，使其看起来更加逼真 */
    border-top: 1px solid rgba(255,255,255,.8);
    border-left: 1px solid rgba(255,255,255,.8);
    margin-bottom: 20px;
}
.IntroduceTitle{
    min-width:50vh;
}
.IntroduceTitle span{
    font-size: 65px;
    border-bottom: 5px #5BC2E7 solid;
}
.IntroduceContent{
    word-wrap: anywhere;
    min-width:50vh;
    max-width:50vh;
    height: auto;
    padding: 10px 20px 0;
}