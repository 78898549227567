.seriesStage {
    width: 70%;
    position: relative;
    border-radius: 15px;
    /* 设置card的背景色 */
    background-color: rgba(255, 255, 255, 0.71);
    backdrop-filter: blur(5px);

    margin: 30px 0;
}

.stageImg:hover {
    transform: scale(1.1);
    transition: transform 2s;
}