.Book2 {
    position: absolute;
    top: 1%;
}

.page-cover{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
}

.b2title{
    color: #ffffff;
    text-shadow: 0 0 30px #1f05b4;
    font-size: 100px;
}

.page {
    padding: 20px;
    min-width: 399px;
    min-height: 500px;
    overflow: auto;
    max-height: 500px;
    max-width: 399px;
    /*background-color: rgba(204,232,207);*/
    /*background-color: rgba(236,192,69);*/
    /*background-color: rgba(255, 255, 255);*/
    background-color: rgba(250, 249, 222);
    /*background-color: rgba(255, 242, 226);*/
    /*background-color: rgba(253, 230, 224);*/
    /*background-color: rgba(227, 237, 205);*/
    /*background-color: rgba(220, 226, 241);*/
    /*background-color: rgba(233, 235, 254);*/
    /*background-color: rgba(234, 234, 239);*/
}


.page * {
    margin: 0;
    padding: 0;
}

.b2left {
    box-shadow: -3px 0 0 0 rgba(0, 0, 0, 0.5);
}

.b2right {
    box-shadow: 3px 0 0 0 rgba(128, 128, 128, 0.5);
}

/*滚动条样式*/
.page::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.page::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
}

.page::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
}

@media (max-width: 850px) {
    .page {
        min-width: 300px;
        max-width: 300px;
        overflow: auto;
    }
}

@media (max-width: 650px) {
    .page {
        min-width: 250px;
        max-width: 250px;
        overflow: auto;
    }
}

@media (max-width: 550px) {
    .page {
        min-width: 200px;
        max-width: 200px;
        overflow: auto;
    }
}

@media (max-width: 450px) {
    .page {
        min-width: 150px;
        max-width: 150px;
        overflow: auto;
    }
}