:where(.css-1b9762u).ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date-content,
:where(.css-dev-only-do-not-override-1b9762u).ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date-content {
    height: 50px;
}

.room {
    position: relative;
    box-shadow: 0 20px 20px 20px #e5e5e5;
    min-width: 50%;
    margin-bottom: 10px;
    border-radius: 10px;
}

.roomBG {
    position: relative;
    background-size: 100% 100%;
    background-position: center;
    min-width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    overflow: auto;
    padding-top: 20px;
}

.roomBG::-webkit-scrollbar {
    display: none; /* Chrome, Safari 和 Opera */
}

.essence {
    padding-left: 3px;
    z-index: 100;
    position: absolute;
    top: 90px;
    background-color: rgba(202, 76, 245, 0.35);
    min-width: 100%;
    max-height: 23px;
    color: #ede5c1;
    font-size: larger;
}

.essence span {
    color: #ffffff;
}

.essencePopup {
    padding: 20px;
    position: absolute;
    top: 39%;
    right: 25%;
    background-color: white;
    min-width: 50%;
    min-height: 30%;
    border-radius: 10px;
    z-index: 100;
}

.message {
    margin: 20px;
}

.avatar {
    max-height: 50px;
    max-width: 50px;
    border-radius: 30px;
}

.pf {
    max-height: 70px;
    max-width: 70px;
    display: flex;
    position: absolute;
    margin-left: -10px;
    margin-top: -10px;
}

.msgBody {
    margin: 0 20px 10px;
}

.msgInfo {
    margin-bottom: 10px;
    max-height: 15px;
}

.team {
    margin: 0 5px;
    max-height: 100%;
}

.msgText {
    padding: 10px 15px 10px;
    background-color: #5BC2E7;
    border-radius: 10px;
}

.msgReply {
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.50);
    border-radius: 13px;
    color: rgba(255, 255, 255, 0.96);
}

.essenceButton {
    margin-top: 20px;
    height: auto;
    min-width: 100px;
    padding: 10px;
    border-radius: 30px;
}

@media (max-width: 1000px) {
    .room {
        min-width: 90%;
    }
}

@media (max-width: 350px) {
    .essence {
        font-size: 15px;
    }
}