.archivesTitle {
    width: 100%;
    height: 60vh;
    margin-bottom: 20px;
    position: relative;
    /* 设置阴影 */
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    /* 设置card的背景色 */
    background: rgba(255, 255, 255, 0.6);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);

}

.elementBlock {
    padding: 0 25px 0;
}

.elementTitle {
    font-size: 23px;
    margin: 20px 0;
}

.elementContent {
    min-width: 270px;
    width: 80%;
    height: 80vh;
}

.elementIframe {
    min-width: 270px;
    width: 70%;
    height: 80vh;
}

.elementAudio {

}

.elementImg {

}

.elementTodayBlock {
    margin: 30px 0;
    width: 70%;
    position: relative;
    /* 设置阴影 */
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    /* 设置card的背景色 */
    background: rgba(255, 255, 255, 0.9);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
}

.elementTodayDiv {
    user-select: none;
    margin: 20px 0;
    padding: 20px;
    /*width: 30%;*/
    position: relative;
    /* 设置阴影 */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    /* 设置card的背景色 */
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.elementTodayDiv:hover {
    box-shadow: 0 0 20px rgba(22, 184, 243, 0.9);
}

.elementToEvent {
    margin: 20px 0;
    padding: 10px;
    background-color: #000000;
    color: whitesmoke;
    height: auto;
}

@media (max-width: 900px) {
    .elementIframe {
        height: 60vh;
    }
}

@media (max-width: 650px) {
    .elementIframe {
        height: 50vh;
    }
}

@media (max-width: 650px) {
    .elementIframe {
        height: 200px;
    }
}