@font-face {
  font-family: "iconfont"; /* Project id  */
  src: url('iconfont.ttf?t=1697001849953') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-xiajiantou:before {
  content: "\eb6d";
}

.icon-shangjiantou:before {
  content: "\eb6e";
}

