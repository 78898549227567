.CrewContent1 {
    min-height: 10vh;

}

.CrewContent2 {
    min-height: 100vh;
    padding: 10px;
}

.CrewUl {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.CrewUl li {
    list-style: none;
}

.CrewUl li a {
    white-space: nowrap;

    position: relative;
    font-size: 3em;
    text-decoration: none;
    line-height: 1em;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: transparent;
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.5);
}

.CrewUl li a::before {
    white-space: nowrap;
    content: attr(data-text);
    position: absolute;
    color: var(--c);
    width: 0;
    overflow: hidden;
    transition: 1s;
    border-right: 8px solid var(--c);
    -webkit-text-stroke: 1px var(--c);
}

.CrewUl .active {
    filter: drop-shadow(0 0 25px var(--c));
}

.CrewUl .active::before {
    padding-left: 15.5px;

    white-space: nowrap;
    content: attr(data-text);
    border-right: 8px solid var(--c);
    -webkit-text-stroke: 1px var(--c);
    color: var(--c);
    width: 100%;
}

.CrewUl li a:hover::before {
    padding-left: 15.5px;
    width: 100%;
    filter: drop-shadow(0 0 25px var(--c));
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-prev:focus {
    font-size: x-large;
    color: #16b8f3;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-next:focus {
    font-size: x-large;
    color: #16b8f3;
}

.CrewIcon {
    max-height: 100px;
    min-height: 100px;
    max-width: 100px;
    min-width: 100px;
}

.CrewAvatar {
    user-select: none;
    max-height: 300px;
    min-height: 100px;
    max-width: 300px;
    min-width: 100px;
    margin-right: 30px;
    border-radius: 50%;
}

.CrewName{
    user-select: none;
    padding-right: 20px;
    margin: 0;
    color: var(--c2);
    writing-mode: vertical-lr;
    text-orientation: upright;
}

.CrewNameSmall {
    margin-right: 10px;
    user-select: none;
    max-width: 20px;
    max-height:50px;
    overflow: hidden;
    color: var(--c2);
    writing-mode: vertical-lr;
    text-orientation: upright;
}

.CrewSlogan {
    height: 250px;
    padding-left: 10px;
    border-left: 1px solid transparent;
    border-image: linear-gradient(to top, #16b8f3, #16b8f3, #16b8f3, grey, grey, transparent) 1; /* 30px border width */
    color: #cfcfcf;
}

.CrewLine {
    border-top: 1px solid transparent; /* 设置线的颜色和宽度 */
    border-image: linear-gradient(to right, #16b8f3, #16b8f3, #16b8f3, grey, grey, transparent) 1; /* 30px border width */
}

.CrewDetail {
    height: 250px;
    padding-top: 10px;
    padding-left: 10px;
    border-left: 1px solid transparent;
    border-image: linear-gradient(to bottom, #16b8f3, #16b8f3, #16b8f3, grey, grey, transparent) 1; /* 30px border width */
    color: #cfcfcf;
}

.CrewSmallShell{
    height: 150px;
    max-height: 150px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}


.CrewSmallShell::-webkit-scrollbar {
    width: 6px; /* 滚动条宽度 */
    background-color: transparent; /* 滚动条背景色 */
    /*display: none;*/
}

/* 自定义滚动条轨道 */
.CrewSmallShell::-webkit-scrollbar-track {
    background: transparent; /* 轨道背景色 */
    border-radius: 10px; /* 轨道圆角 */
}

/* 自定义滚动条的滑块（thumb） */
.CrewSmallShell::-webkit-scrollbar-thumb {
    background-color: #16b8f3; /* 滑块背景色 */
    border-radius: 10px; /* 滑块圆角 */
    border: 2px solid #16b8f3; /* 滑块边框 */
}

/* 当滑块悬停或活动时的样式 */
.CrewSmallShell::-webkit-scrollbar-thumb:hover {
    background: #16b8f3; /* 悬停或活动状态下滑块背景色 */
}

.CrewSmallRow{
    margin: 10px 0;
}

.CrewSmall{
    user-select: none;
    margin: 0;
    /*margin-left: 30px;*/
    max-height:50px;
    min-height:50px;
    max-width: 50px;
    min-width: 50px;
}

/*轮播图元素多了之后隐形高度和宽度会增加*/
.CrewTarget .slick-slider .slick-list{
    min-height: 540px;
    max-height: 540px;
}