.recordBox {
    margin: 20px 0;
    padding:  20px;
    min-height: 150px;
    min-width: 290px;
    position: relative;
    /* 设置阴影 */
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    /* 设置card的背景色 */
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
}

.block2font {
    min-height: 300px;
    min-width: 600px;
    position: relative
}

.special{
    height: 870px;
    position: relative;
}

.block3font{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.block3font1 {
    top: 10%;
    left: 3%;
    min-height: 220px;
    min-width: 300px;
    max-height: 220px;
    max-width: 300px;
    background: rgba(91, 194, 231, 0.5);
}

.block3font2 {
    bottom: 5%;
    right: 2%;
    min-height: 250px;
    min-width: 500px;
    max-height: 250px;
    max-width: 500px;
    background: rgba(91, 194, 231, 0.5);
}

.block3img {
    position: absolute;
}

.block3img1 {
    height: 390px;
    width: 290px;
    top: 39%;
    left: 20%;
    z-index: 3;
}

.block3img2 {
    height: 390px;
    width: 390px;
    top: 10%;
    left: 33%;
    z-index: 2;
}

.block3img3 {
    height: 330px;
    width: 540px;
    max-width: 39%;
    top: 20%;
    left: 60%;
    z-index: 1;
}