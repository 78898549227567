/* 设置.shell元素为相对定位，flex布局，水平和垂直居中对齐，宽度和高度为100%，盒模型为border-box，背景颜色为rgba(99, 99, 99, 0.8) */
.c2shell {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    background-size: cover;
    overflow: hidden;
}

.ant-modal-content {
    background-color: transparent;
}

/* 设置.button元素为flex布局，两端对齐，宽度为380px，绝对定位，左侧偏移量为50%，水平居中，底部偏移量为-80px */
.c2button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -80px;
}

/* 设置.prev和.next元素过渡动画时间为0.25秒，层级为99999，底部偏移量为5px */
.c2prev,
.c2next {
    transition: transform 0.25s ease;
    z-index: 99999;
    bottom: 5px;
}

/* 设置.prev和.next元素中的i元素字体大小为90px，颜色为#fff，光标为指针，文字阴影为0 0 10px #ffffff */
.c2prev i,
.c2next i {
    font-size: 90px;
    color: #fff;
    cursor: pointer;
    text-shadow: 0 0 10px #ffffff;
}

/* 设置.shell_body元素宽度为100%，缩放为0.8倍，上内边距为20px，下内边距为150px */
.c2shell_body {
    width: 100%;
    transform: scale(.55);
    padding: 20px 0 150px 0;
}

/* 设置.shell_slider元素为相对定位，过渡动画时间为1秒，背景为透明 */
.c2shell_slider {
    position: relative;
    transition: transform 1s ease-in-out;
    background: transparent;
}

/* 设置.item元素为相对定位，左浮动，左右外边距为20px */
.c2item {
    position: relative;
    float: left;
    margin: 0 20px;
}

/* 设置.frame元素为相对定位，宽度和高度为100%，过渡动画时间为1秒，3D变换模式为保留3D效果 */
.c2frame {
    user-select: none;
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 1s ease-in-out;
    transform-style: preserve-3d;
}

/* 设置.frame元素的伪元素为绝对定位，底部偏移量为-16%，宽度为100%，高度为60px，背景颜色为#ffffff1c，盒阴影为0px 0px 15px 5px #ffffff1c，3D变换为绕X轴旋转90度并向上平移20px */
.c2frame:after {
    content: "";
    position: absolute;
    bottom: -16%;
    width: 100%;
    height: 60px;
    background: #ffffff1c;
    box-shadow: 0px 0px 15px 5px #ffffff1c;
    transform: rotateX(90deg) translate3d(0px, -20px, 0px);
}

/* 设置.box元素为flex布局，纵向排列，水平和垂直居中对齐，绝对定位，宽度和高度为100%，边框为4px实心白色，透视效果为1000px，3D变换模式为保留3D效果 */
.c2box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 4px solid #fff;
    perspective: 1000px;
    transform-style: preserve-3d;
}

/* 设置.box元素中的h1和span元素颜色为#fff，Z轴平移距离为20px */
.c2box h1,
.c2box span {
    color: #fff;
    transform: translateZ(20px);
}

/* 设置.box元素中的h1元素文字阴影为0 0 30px #1f05b4，字体大小为100px */
.c2box h1 {
    text-shadow: 0 0 30px #1f05b4;
    font-size: 100px;
}

/* 设置.box元素中的span元素为绝对定位，底部偏移量为20px，左右内边距为25px，文字阴影为0 0 10px #1f05b4 */
.c2box span {
    position: absolute;
    bottom: 20px;
    padding: 0 25px;
    text-shadow: 0 0 10px #1f05b4;
}

/* 设置.front、.left和.right元素的盒阴影为0 0 50px #ffffff，背景图大小为cover */
.c2front,
.c2left,
.c2right {
    box-shadow: 0 0 50px #ffffff;
    background-size: cover;
}

/* 设置.left和.right元素的顶部偏移量为0，宽度为60px，背面不可见 */
.c2right,
.c2left {
    top: 0;
    width: 60px;
    backface-visibility: hidden;
}

/* 设置.left元素的左侧偏移量为0，左边框宽度为5px，3D变换为向右平移1px，Z轴平移-60px，绕Y轴逆时针旋转90度，变换原点为左侧 */
.c2left {
    left: 0;
    border-left-width: 5px;
    transform: translate3d(1px, 0, -60px) rotateY(-90deg);
    transform-origin: 0%;
}

/* 设置.right元素的右侧偏移量为0，右边框宽度为5px，3D变换为向左平移1px，Z轴平移-60px，绕Y轴顺时针旋转90度，变换原点为右侧 */
.c2right {
    right: 0;
    border-right-width: 5px;
    transform: translate3d(-1px, 0, -60px) rotateY(90deg);
    transform-origin: 100%;
}

.c2iframe {
    width: 100%;
    height: 80vh;
}

:where(.css-1b9762u).ant-modal .ant-modal-content,
:where(.css-dev-only-do-not-override-1b9762u).ant-modal .ant-modal-content {
    background-color: transparent;
    box-shadow: 0 0 0 0
}