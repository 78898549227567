.tlShell {
    width: 100%;
    position: relative;
    padding: 80px 0;
    transition: 0.3s ease 0s;
    background-attachment: fixed;
    background-size: cover;
}

.tlShell::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(99, 99, 99, .8);
    content: "";
}

.tlHeader {
    width: 100%;
    text-align: center;
    margin-bottom: 80px;
    position: relative;
}

.tlTitle {
    color: #fff;
    font-size: 46px;
    font-weight: normal;
    margin: 0;
}

.timeline {
    display: flex;
    margin: 0 auto;
    flex-wrap: wrap;
    flex-direction: column;
    max-width: 700px;
    min-height: 100vh;
    position: relative;
}

.tl-content-title {
    font-weight: normal;
    font-size: 50px;
    margin: -10px 0 0 0;
    transition: 0.4s;
    padding: 0 10px;
    box-sizing: border-box;
    color: #fff;
}

.tl-content-desc {
    margin: 0;
    font-size: 15px;
    box-sizing: border-box;
    color: rgba(255, 255, 255, .7);
    line-height: 25px;
}

.timeline::before {
    position: absolute;
    left: 50%;
    width: 2px;
    height: 100%;
    margin-left: -1px;
    content: "";
    background: rgba(255, 255, 255, .07);
}

.tlItem {
    padding: 40px 0;
    opacity: 0.3;
    filter: blur(2px);
    transition: 0.5s;
    box-sizing: border-box;
    width: calc(50% - 40px);
    display: flex;
    position: relative;
    transform: translateY(-80px);
}

.tlItem:hover{
    transform: scale(1.2);
}

.tlItem::before {
    content: attr(data-text);
    /*letter-spacing: 3px;*/
    width: 100%;
    position: absolute;
    color: rgba(255, 255, 255, .5);
    /*font-size: 13px;*/
    border-left: 1px solid rgba(255, 255, 255, .5);
    top: 70%;
    margin-top: -5px;
    padding-left: 15px;
    opacity: 0;
    right: calc(-100% - 56px);
    font: 900 20px '';
    letter-spacing: 5px;
}

.tlItem:nth-child(even) {
    align-self: flex-end;
}

.tlItem:nth-child(even)::before {
    right: auto;
    text-align: right;
    left: calc(-100% - 56px);
    padding-left: 0;
    border-left: 2px solid rgba(255, 255, 255, .5);
    padding-right: 15px;
}

.tlItem--active {
    opacity: 1;
    transform: translateY(0);
    filter: blur(0px);
}

.tlItem--active::before {
    top: 50%;
    transition: 0.3s all 0.2s;
    opacity: 1;
}

.tlItem--active .tl-content-title {
    margin: -50px 0 20px 0;
}

.tlImg {
    max-width: 100%;
    /* min-width: 100%; */
    box-shadow: 0 10px 15px rgba(0, 0, 0, .4);
}

.subtitle {
    color: rgba(255, 255, 255, .5);
    font-size: 1rem;
    letter-spacing: 5px;
    margin: 10px 0 0 0;
    font-weight: normal;
}

.footer {
    padding: 95px 0;
    text-align: center;
}

.footer a {
    color: #999;
    display: inline-block;
}

@media only screen and (max-width: 767px) {
    .tlItem {
        align-self: baseline !important;
        width: 100%;
        padding: 0 30px 150px 80px;
    }

    .tlItem::before {
        left: 10px !important;
        padding: 0 !important;
        top: 50px;
        text-align: center !important;
        width: 60px;
        border: none !important;
    }

    .tlItem:last-child {
        padding-bottom: 40px;
    }
}

@media only screen and (max-width: 767px) {
    .timeline::before {
        left: 40px;
    }
}
