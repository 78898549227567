.c1shell {
    display: flex;
    align-items: center;
    padding: 0;
    width: 100%;
    justify-content: center;
}

.c1carousel {
    /*position: relative;*/
    width: 399px;
    perspective: 300px;
}

.c1boxs {
    width: 100%;
    /*要与box的高度一起动*/
    height: 399px;
    position: absolute;
    transform-style: preserve-3d;
    transition: 1s;
}

.c1box {
    pointer-events: none;

    position: absolute;
    width: 399px;
    /*要与boxs的高度一起动*/
    height: 399px;
    border-radius: 10px;
    overflow: hidden;
    opacity: 0.7;
    background-color: #586b94;
    color: #ffffff;
}

.c1box .c1dec {
    font-size: 50px;
    font-weight: 100;
    margin: 20% 0 10%;
    white-space: nowrap;
}

.c1box .c1title {
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 5%;
}

.c1box .c1content {
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1.5px;
    overflow: auto;
}

.c1content::-webkit-scrollbar {
    width: 1px; /* 滚动条宽度 */
}

/* 自定义滚动条轨道 */
.c1content::-webkit-scrollbar-track {
    border-radius: 3px; /* 轨道圆角 */
}

/* 自定义滚动条的滑块（thumb） */
.c1content::-webkit-scrollbar-thumb {
    background-color: #16b8f3; /* 滑块背景色 */
}

.c1box .c1item {
    width: 100%;
    height: 80%;
}

.c1box.c1selected {
    pointer-events: unset;

    border: 2px solid goldenrod;
    opacity: 1;
}

.c1arrows {
    display: flex;
    flex-direction: column;
}

.c1up,
.c1next {
    margin: 5px 0;
    padding: 5px;
    width: 50px;
    height: 50px;
    border: 3px solid rgb(0, 0, 0);
    border-radius: 10px;
    background-color: #ffffff61;
    color: #000000;
    transition: .3s;
}

.c1up:hover,
.c1next:hover {
    background-color: #fff;
}

@media (max-width: 1024px) {

    .c1carousel {
        width: 350px;
    }

    .c1boxs {
        height: 350px;
    }

    .c1box {
        width: 350px;
        height: 350px;
    }

}

@media (max-width: 768px) {

    .c1carousel {
        width: 300px;
    }

    .c1boxs {
        height: 300px;
    }

    .c1box {
        width: 300px;
        height: 300px;
    }

    .c1box .c1dec {
        font-size: 39px;
    }

    .c1box .c1title {
        font-size: 20px;
    }

    .c1box .c1content {
        letter-spacing: 1px;
    }

}

@media (max-width: 450px) {

    .c1carousel {
        width: 200px;
    }

    .c1boxs {
        height: 200px;
    }

    .c1box {
        width: 200px;
        height: 200px;
    }

    .c1up,
    .c1next {
        padding: 0;
        width: 30px;
        height: 30px;
    }


    .c1box .c1dec {
        font-size: 27px;
    }

    .c1box .c1title {
        font-size: 20px;
        margin-bottom: 1%;
    }

    .c1box .c1content {
        letter-spacing: 0;
    }

}