.archivesImg:hover {
    animation: archivesImgScale 5s infinite ;
}

.archivesName {
    margin: 30px auto 0;
    font-size: 30px;
}

@media (max-width: 425px) {
    .archivesName {
        font-size: 20px;
    }
}
@keyframes archivesImgScale {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}